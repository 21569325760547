import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../components/layout/layout";
import AdvantagesSection from "../components/advantagesSection";
import Description from "../components/description";
import BlogsContainer from "../components/blogsContainer";
import Modal from "../components/modal";
import GetNewsletter from "../components/getNewsletter";
import { SeoEN } from "../components/seo";
import "../assets/css/index.css";
import PlansConatiner from "../components/plansConatiner";
import Templates from "../components/templates";
import EventsTypesSection from "../components/eventsTypesSection";

const IndexPage = ({ location }) => {
  const [isModalopen, setIsModalopen] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  function logit() {
    setScrollY(scrollY + 1);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  useEffect(() => {
    if (scrollY > 180 && !isModalopen) {
      setModalopen(true);
      setIsModalopen(true);
    }
  }, [scrollY]);

  return (
    <Layout page="home" language="en">
      <Description language="en" location={location} />
      <AdvantagesSection language="en" />
      <Templates language="en" />
      <PlansConatiner language="en" />
      <EventsTypesSection language="en" />
      <BlogsContainer language="en" />
      <Modal onOpen={modalopen} setOnOpen={setModalopen} buttonOn>
        <GetNewsletter setOnOpen={setModalopen} language="en" />
      </Modal>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <SeoEN
    hrefLangs={[
      { href: "https://kolibird.app/invitaciones-gratis", lang: "es" },
      { href: "https://kolibird.app/en/free-invitations", lang: "en" },
    ]}
  />
);
